
import React from "react"

import ListmanagerCategorytagPage from "../../components/negosyonow/listmanagers/categorytag"

const NNPARENTTAGID=45


const nntagaliaspickerfields = [
	{"label":"Name", "dbfield": "nntagalias_name", "type": "text", "filtertype": "textbox"},
	{"label":"Image", "dbfield": "nntagalias_image", "type": "image", "filtertype": "image"},
	{"label":"Active", "dbfield": "nntagalias_active", "type": "checkbox", "filtertype": "checkbox"},
];

const nntagaliaspickerfilter = [
	{"field":"nntagalias_parent", "operation": "=", "value": 5}
];

const searchFields = [
	{"label":"Order", "dbfield": "nnchildtag_order", "type": "integer", "filtertype": "integer"},
	{"label":"Name", "dbfield": "nntagalias_name", "type": "text", "filtertype": "text"},
	{"label":"Image", "dbfield": "nntagalias_image", "type": "image", "filtertype": "image"},
];


const formFields = [
	[
		{"label":"Order", "field": "nnchildtag_order", "value": "", "input": "integer", "mode": "required"},
		{"label":"Business", "field": "nntagalias_name", "value": "", "input": "picker", "mode": "required", "pickerfields": nntagaliaspickerfields, "pickerfilters": nntagaliaspickerfilter},
		{"label":"Image", "field": "nntagalias_image", "value": "", "input": "image", "mode": "readonly"},
	]
];




const FeaturedbusinessPage = ({location}) => {


	return <ListmanagerCategorytagPage
			location={location}
			parenttagid={NNPARENTTAGID}
			formFields={formFields}
			searchFields={searchFields}
		/>
}


export default FeaturedbusinessPage;
